/* You can add global styles to this file, and also import other style files */
// regular style toast
// @import '~ngx-toastr/toastr';
@import "~bootstrap-icons/font/bootstrap-icons.css";

@font-face {
    font-family: 'CSChatThaiUI';
    src: url('~/assets/font/CSChatThaiUI.otf');
    font-weight: normal;
}
@font-face {
    font-family: 'CSChatThaiUI_bold';
    src: url('~/assets/font/CSChatThaiUI-Bold.otf');
    font-weight: bold;
}

* {
    font-family: 'CSChatThaiUI';
}

b {
    font-family: 'CSChatThaiUI_bold';
}


.card {
    border: none !important;
    box-shadow: 0 0 12px 0 #f4f4f4;

    .card-header {
        border: none;
        background: #fff;
        // background-color: #3867d6;
        padding: 15px 15px;
        display: inline-block !important;
        vertical-align: middle !important;
        border-bottom: 1px solid #eee;
    }

    .card-body {
        border: none;
        overflow: auto !important;
        height: 70vh !important;
    }
}

.table>:not(caption)>*>* {
    border-color: #eee;
}

th,
td {
    font-size: 15px;
    padding: 2px;

    button {
        font-size: 14px !important;
    }
}

.table>:not(caption)>*>* {
    vertical-align: middle;
}

.form-control {
    min-height: 45px;
    border-radius: 8px;
    border: 1px solid #eee;
}

.card-header {
    ul {
        list-style-type: none;
        display: flex;
        margin: 0px;
        padding: 0px;
        justify-content: flex-end;
        align-items: center;

        .titletext {
            margin: 0 auto 0 0;
            font-size: 18px;
            font-weight: bold;
        }

        li {
            margin-left: 15px;

            input , select {
                min-height: 45px;
                height: 45px;
            }

            .btn {
                height: 45px;
                display: flex;
                padding-top: 8px;

                i {
                    margin-right: 8px;
                    color: #fff;
                }
            }
        }
    }
}

.card-footer { 
    background-color: #fff !important;
    padding: 15px !important;
}

.listdetail {
    list-style-type: none;
    display:flex;
    margin:0px !important;
    padding:0px;
    margin-bottom: 10px;

    li {
        padding: 15px 20px;
        background-color: #fff;
        border-right: 1px solid #eee;
        font-size: 16px;
        cursor: pointer;
    }

    li:hover {
        background-color: #3867d6;
        color: #fff;
    }

    .active {
        background-color: #3867d6;
        color: #fff;
        font-weight: bold;
    }
}